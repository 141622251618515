import React from "react";

function FilterFinishedComponent({ setFinished }) {
  return (
    <div className="filter-drop-down-general-design">
      <select onChange={(e) => setFinished(e.target.value)}>
        <option value="">All</option>
        <option value="true">Finished</option>;
        <option value="false">UnFinished</option>;
      </select>
    </div>
  );
}

export default FilterFinishedComponent;
