import {Outlet,Navigate,useLocation} from "react-router-dom"
import { useSelector } from "react-redux"

export default function RequireAuth(){
    const location = useLocation();
    const authState = useSelector((state)=>state.auth);

    return(
        authState?.user?<Outlet/>
        :<Navigate to="/user/signin" state={{from:location}} replace />
    )
}