import React from "react";
import ViewTitle from "./ViewTitle";
import MainView from "./MainView";
import "../../css/view.css";
export default function View() {
  return (
    <div className="view-container">
      <MainView></MainView>
    </div>
  );
}
