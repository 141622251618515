import React from "react";
import "../../css/filter.css";

function GetYearOfArray() {
  const yearlist = [];
  let oldYear = 2020;
  const currentYear = new Date().getFullYear();
  for (var a = oldYear; a < currentYear; a++) {
    oldYear += 1;
    yearlist.unshift(oldYear);
  }
  return yearlist;
}

const monthlist = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function FilterDate({ setYear, setMonth }) {
  return (
    <div className="filter-year-and-month-container">
      <div className="filter-drop-down-general-design">
        <select name="" id="" onChange={(e) => setYear(e.target.value)}>
          <option value="">All Year</option>
          {GetYearOfArray().map((item,index) => {
            return <option value={item} key={index}>{item}</option>;
          })}
        </select>
      </div>
      <div className="filter-drop-down-general-design">
        <select name="" id="" onChange={(e) => setMonth(e.target.value)}>
          <option value="">All Month</option>
          {monthlist.map((item, index) => {
            return <option value={index + 1} key={index}>{item}</option>;
          })}
        </select>
      </div>
    </div>
  );
}

export default FilterDate;
