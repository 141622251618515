import React, { useEffect, useState } from "react";

import logo from "../../img/logo192.png";
import "../../css/newpassword.css";

//css
import "../../css/spforma.css";
import "../../css/signin.css";
import { useParams } from "react-router-dom";
import { useResetPasswordMutation } from "../../handler/auth.api";
import { ToastError, SuccessToast } from "../Toast/Notification";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function NewPassword() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  const [togglec, setTogglec] = useState(false);
  const [passsword, setPassword] = useState("");
  const [confirmPasssword, setConfirmPassword] = useState("");
  const [alert, setAlert] = useState(false);
  const [alertc, setAlertc] = useState(false);

  const [resetPasswordApi, { isLoading, isSuccess, data, isError, error }] =
    useResetPasswordMutation();

  const validateForm = () => {
    return passsword === confirmPasssword.length >=8 && passsword.length >= 8;
  };
  const formHandler = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      setAlert(true);
      setAlertc(true);
      try {
        const response = await resetPasswordApi({
          password: passsword,
          confirm: confirmPasssword,
          token: id,
        });
      } catch (err) {
        ToastError(err.message);
      }
    }
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setAlert(false);
  };
  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setAlertc(false);
  };

  const showIcon = () => {
    setToggle(!toggle);
  };
  const showIconc = () => {
    setTogglec(!togglec);
  };

  useEffect(()=>{
    if(isSuccess){
      SuccessToast(data.message);
      navigate('/user/signin')
    }else if(isError){
      ToastError(error.data.message);
    }
  },[isSuccess,isError])

  return (
    <>
      <div className="d-flex align-items-center justify-content-center sign-main-container">
        <div className="signin-container">
          <div className="bg-danger d-flex  align-items-center justify-content-center p-2">
            <div className="signin-img">
              <img src={logo} alt="jklabrang" className="signlogo" />
            </div>
            <div className="sigin-title h4 mb-0 text-capitalize text-white">
              Jamyang knogturlku labrang
            </div>
          </div>

          <div className="signsection ">
            <div className="h5 text-center  text-danger">
              Enter new password
            </div>
            <div className="mb-4 text-center text-danger-emphasis">
              Must be 8 character.
            </div>
            <form onSubmit={formHandler}>
              <div className="mb-3 position-relative">
                <input
                  type={toggle ? "text" : "password"}
                  className={`fi-input ${alert ? "errorBorder" : "fi-input"}`}
                  id="password"
                  onChange={handlePasswordChange}
                  value={passsword}
                  placeholder="Choose a password"
                />
                <div className="showIcon" onClick={showIcon}>
                  <i
                    className="fa-solid fa-eye"
                    style={{ color: toggle ? "black" : "rgb(95, 94, 94)" }}
                  ></i>
                </div>
              </div>
              <div className="mb-3 position-relative">
                <input
                  type={togglec ? "text" : "password"}
                  className={`fi-input ${alertc ? "errorBorder" : "fi-input"}`}
                  onChange={handleConfirmPasswordChange}
                  id="passwprd"
                  placeholder="Confirm password"
                />
                <div className="showIcon" onClick={showIconc}>
                  <i
                    className="fa-solid fa-eye"
                    style={{ color: togglec ? "black" : "rgb(95, 94, 94)" }}
                  ></i>
                </div>
              </div>
              <button type="submit" className="btn btn-primary">
                {isLoading ? <div className="spinnerloader "></div> : "Submit"}
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
