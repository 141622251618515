import React, { useRef } from "react";
import { CheckOrderProcess } from "../../../utils/dateChecker";
//react print
import { ReactToPrint } from "react-to-print";

export default function MoreDetail({
  item,
  isVisible,
  handleClose,
  handleSelectChange,
  selectedPaidStatus,
}) {
  const componentRef = useRef(null);

  return (
    <div
      className={`sm-more ${isVisible ? "visible" : "hidden"}`}
      ref={componentRef}
    >
      <div className="ol-more-title">
        <div className="olmt">Order List Detail</div>
        <div className="olmi">
          <i className="fa-solid fa-xmark" onClick={handleClose}></i>
        </div>
      </div>
      <div className="ol-more-d">
        <div className="ol-id">
          <div className="oltype">puja name</div>
          <div className="colon-none">:</div>
          <div>{item.pujaName}</div>
          <div className="oltype">Duration</div>
          <div className="colon-none">:</div>
          <div>{item.duration}</div>
        </div>
        <div className="ol-id">
          <div className="oltype">id</div>
          <div className="colon-none">:</div>
          <div>{item.id}</div>
          <div className="oltype"> Co.Code</div>
          <div className="colon-none">:</div>
          <div>
            {item?.sponsor?.coordinator?.code
              ? item.sponsor?.coordinator?.code
              : `No Coord`}
          </div>
        </div>
        <div className="ol-id">
          <div className="oltype">Cost</div>
          <div className="colon-none">:</div>
          <div>
            <span className="me-1">
              {item.currency == "ALL" ? "USD" : item.currency}
            </span>
            {item.cost}
          </div>
          <div className="oltype">Actual cost</div>
          <div className="colon-none">:</div>
          <div>
            <span className="me-1">
              {item.currency == "ALL" ? "USD" : item.currency}
            </span>
            {item.actualAmount}
          </div>
        </div>
        <div className="ol-id">
          <div className="oltype">start date</div>
          <div className="colon-none">:</div>
          <div>{item.startDate.slice(0, 10)}</div>
          <div className="oltype"> end date</div>
          <div className="colon-none">:</div>
          <div>{item.endDate.slice(0, 10)}</div>
        </div>
        <div className="ol-id">
          <div className="oltype">payment</div>
          <div className="colon-none">:</div>
          <div>
            <select
              value={selectedPaidStatus}
              onChange={handleSelectChange}
              readOnly
              className="ol-select form-select"
            >
              <option value="paid">paid</option>
              <option value="unpaid">unpaid</option>
            </select>
          </div>
          <div className="oltype"> process</div>
          <div className="colon-none">:</div>
          <div>
            <select
              className="ol-select form-select"
              value={
                CheckOrderProcess(item.endDate) ? "finfished" : "unfinished"
              }
              readOnly
            >
              <option value="finished">finished</option>
              <option value="unfinished">unfinished</option>
            </select>
          </div>
        </div>
        <div className="row mx-auto my-2">
          <div className="col-12 col-sm-6">
            <ReactToPrint
              trigger={() => {
                return (
                  <div className="btn btn-danger">Download a Prayer Card</div>
                );
              }}
              content={() => componentRef.current} // Corrected reference
              documentTitle="Printing title"
              pageStyle="print"
            />
          </div>
          <div className="col-12 col-sm-6 my-2 my-sm-0">
            <div className="btn btn-warning">Gift a Prayer Card</div>
          </div>
        </div>
        <div className="ol-id">
          <div className="oltype">dedication</div>
          <div className="colon-none">:</div>
        </div>
        <div className="ol-ded px-2 py-1 ol-ded-head">
          <div className="oltyped d-none d-sm-block">S.no</div>
          <div className="oltyped">Name</div>
          <div className="oltyped">email</div>
        </div>
        {item.dedicators && item.dedicators.length > 0 && (
          <>
            {item.dedicators.map((dedi, index) => (
              <div className="ol-ded" key={index}>
                <div className="oltype d-none d-sm-block text-center">
                  {index + 1}
                </div>
                <div className="ol-ded-value">{dedi.name}</div>
                <div className="ol-ded-value">{dedi.email}</div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
}
