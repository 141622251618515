import React from "react";
import "../../css/Puja.css";
import { NavLink } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "../../css/spinner.loader.css";

import { SuccessToast, ToastError } from "../Toast/Notification";
import { ToastContainer } from "react-toastify";

import {
  useGetAllPujaQuery,
  useDeletePujaMutation,
} from "../../handler/puja.api";

// framer motion
import { AnimatePresence, motion } from "framer-motion";
import PujaDeleteComponent from "../PujaDeleteComponent";

export default function Puja() {
  // API INITLIAZATION--------------------
  const getAllPujaApi = useGetAllPujaQuery();

  return (
    <div>
      <div className="puja-container">
        <div className="row w-100 mx-auto">
          <div className="puja-add-button-container">
            <motion.h1
              initial={{ opacity: 0.5, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ ease: "easeOut", duration: 0.2 }}
            >
              {getAllPujaApi.data?.data.length < 1
                ? "Puja list is Empty"
                : `Total Puja list ---  ${getAllPujaApi.data?.data.length}`}
            </motion.h1>
            <NavLink to="/addpuja" className="puja-addnew my-2">
              Add New
            </NavLink>
          </div>

          <div className="puja-white">
            {getAllPujaApi.isLoading && <div className="bigSpinner"></div>}

            <AnimatePresence>
              {getAllPujaApi.data?.data.map((puja, index) => (
                <motion.div
                  key={puja._id}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ x: -200, opacity: 0 }}
                  transition={{ ease: "easeOut", duration: 0.3 }}
                  className="puja-list my-2 row w-100 mx-auto align-items-center justify-content-center p-2"
                >
                  <div className="col-1 d-none d-sm-block browncolor">
                    {index + 1}
                  </div>
                  <div className="col-sm-8 col-12 order-2 my-sm-0 my-2">
                    <div className="browncolor fw-bold fs-5 puja-list-title">
                      {puja.title}
                    </div>
                    <div className="pujaDuration">
                      <span className="browncolor">Duration </span>
                      {puja.durationAndPrice?.map((duration, index) => {
                        return (
                          index < 4 && <p key={index}>{duration.duration}</p>
                        );
                      })}
                      {puja.duration}
                    </div>
                  </div>
                  <div className="col-sm-3 order-1 order-sm-3 col-12 d-flex  justify-content-sm-end justify-content-center">
                    <div className="puja-image">
                      <img src={puja.image} alt="photo" />
                    </div>
                    <div className="d-flex flex-column justify-content-between ms-2">
                      <PujaDeleteComponent id={puja._id} />
                    </div>
                  </div>
                </motion.div>
              ))}
            </AnimatePresence>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
