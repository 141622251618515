import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

// CSS
import "../../../css/forma.css";
import "../../../css/spinner.loader.css";
import "react-toastify/dist/ReactToastify.css";

// API
import { useAddCoordinatorMutation } from "../../../handler/coordinator.api";

// TOAST LIBARARY
import { SuccessToast, ToastError } from "../../Toast/Notification";
import { ToastContainer } from "react-toastify";

//button
import ButtonComponent from "../../form/ButtonComponent";

//countries json
import countries from "../../../json/countries.json";
import Select from "react-select";

export default function Forma() {
  const initialValues = {
    code: "",
    name: "",
    country: "",
    email: "",
    address: "",
    whatsapp: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  // API INITLIAZATION----------------------------------
  const [CoordinatorApi, { isLoading, isError, isSuccess, error }] =
    useAddCoordinatorMutation();

  //countries
  const [selectedCountry, setSelectedCountry] = useState(null);
  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    setFormValues({ ...formValues, country: selectedOption.label });
  };
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const mappedOptions = countries.map((country) => ({
      value: country.code,
      label: country.name,
    }));
    setOptions(mappedOptions);
  }, [selectedCountry]);

  // SUBMIT COORDINATOR DATA TO SERVER-----------------------------------
  const handleFormInput = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const navigate = useNavigate();

  const coordinaterSubmitHandler = async (event) => {
    event.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };
  useEffect(() => {
    const submitForm = async () => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        try {
          const formData = new FormData();
          formData.append("code", formValues.code);
          formData.append("name", formValues.name);
          formData.append("country", formValues.country);
          formData.append("email", formValues.email);
          formData.append("address", formValues.address);
          formData.append("whatsapp", formValues.whatsapp);

          const response = await CoordinatorApi(formData);
          console.log("API Response:", response);
          navigate("/coordinator");
          SuccessToast("Successfully created Coordinator");
        } catch (err) {
          console.error("API Error:", err);
          ToastError(err.message);
        }
      }
    };
    submitForm();
  }, [formErrors, isSubmit]); // Add isSubmit to dependencies array

  const validate = (values) => {
    const errors = {};
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;

    if (!values.name) {
      errors.name = "Name is required";
    }
    if (!values.code) {
      errors.code = "Invalid Code number";
    }
    if (!values.country) {
      errors.country = "Please enter country name";
    }
    if (!values.email && !regex.test(values.email)) {
      errors.email = "Invalid email";
    }
    if (!values.address) {
      errors.address = "address name is required";
    }

    return errors;
  };
  // SHOW NOTIFICATION
  useEffect(() => {
    if (isError) {
      ToastError(error?.data?.message);
    }
    if (isSuccess) {
      SuccessToast("Successfully created Coordinator");
    }
  }, [isError, isSuccess]);
  // ---------------------------------------------------------------

  return (
    <>
      <div>
        <div className="forma-container d-flex align-items-center justify-content-center">
          <div className="forma-box">
            <div className="forma-title fs-3">coordinator Register</div>

            <form className="fs-6" onSubmit={coordinaterSubmitHandler}>
              <div className="fi-grid">
                <div>
                  <label htmlFor="code" className="fi-name">
                    Code
                  </label>
                  <br />
                  <input
                    value={formValues.code}
                    placeholder="Enter your code"
                    type="text"
                    id="code"
                    onChange={handleFormInput}
                    name="code"
                    className={`fi-input ${
                      formErrors.code ? "errorBorder" : "fi-input"
                    }`}
                  />
                  <p className="errorMsg">{formErrors.code}</p>
                </div>
                <div>
                  <label htmlFor="country-label" className="fi-name">
                    country
                  </label>
                  <br />

                  <Select
                    id="country"
                    name="country"
                    className={`${formErrors.country ? "errorBorder" : ""}`}
                    value={selectedCountry}
                    onChange={handleCountryChange}
                    options={options}
                    aria-labelledby="country-label"
                    placeholder="Select a country..."
                  />
                  <p className="errorMsg">{formErrors.country}</p>
                </div>

                <div>
                  <label htmlFor="name" className="fi-name">
                    Name
                  </label>
                  <br />
                  <input
                    value={formValues.name}
                    placeholder="Enter your name"
                    type="text"
                    id="name"
                    onChange={handleFormInput}
                    name="name"
                    autoComplete="given-name"
                    className={`fi-input ${
                      formErrors.name ? "errorBorder" : "fi-input"
                    }`}
                  />
                  <p className="errorMsg">{formErrors.name}</p>
                </div>

                <div>
                  <label htmlFor="email" className="fi-name">
                    email
                  </label>
                  <br />
                  <input
                    value={formValues.email}
                    placeholder="Enter your email"
                    type="text"
                    id="email"
                    onChange={handleFormInput}
                    name="email"
                    autoComplete="email"
                    className={`fi-input ${
                      formErrors.email ? "errorBorder" : "fi-input"
                    }`}
                  />
                  <p className="errorMsg">{formErrors.email}</p>
                </div>

                <div>
                  <label htmlFor="address" className="fi-name">
                    address
                  </label>
                  <br />
                  <input
                    value={formValues.address}
                    placeholder="Enter your address"
                    type="text"
                    id="address"
                    autoComplete="street-address"
                    onChange={handleFormInput}
                    name="address"
                    className={`fi-input ${
                      formErrors.address ? "errorBorder" : "fi-input"
                    }`}
                  />
                  <p className="errorMsg">{formErrors.address}</p>
                </div>
                <div>
                  <label htmlFor="whatsapp" className="fi-name">
                    whatsapp
                  </label>
                  <br />
                  <input
                    value={formValues.whatsapp}
                    placeholder="Enter your whatsapp Number"
                    type="number"
                    id="whatsapp"
                    onChange={handleFormInput}
                    name="whatsapp"
                    className="fi-input "
                  />
                </div>
                <div></div>
              </div>
              <div className="d-flex mt-6 justify-content-end">
                <div>
                  <div className="f-btn">
                    <ButtonComponent>
                      {isLoading ? (
                        <div className="spinnerloader"></div>
                      ) : (
                        "Submit"
                      )}
                    </ButtonComponent>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* TOAST  */}
      <ToastContainer />
    </>
  );
}
