import React, { useContext, useEffect, useState } from "react";
import "../../css/viewTitle.css";
import BurgerContext from "../../context/burgerbutton/BurgerContext";
import { NavLink, useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../features/auth/authSlice";

//user img
import userimg from "../../img/user.png";
import resetIcon from "../../img/reset-password.png";
import logoutIcon from "../../img/logout.png";
function ViewTitle() {
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const location = useLocation();
  const [pageTitle, setPageTitle] = useState("");
  const [toggle, setToggle] = useState(false);
  const profileButton = () => {
    setToggle(!toggle);
    console.log(toggle);
  };
  // Function to set title based on the current pathname
  const setTitle = () => {
    switch (location.pathname) {
      case "/":
        setPageTitle("Dashboard");
        break;
      case "/puja":
        setPageTitle("Daily Pujas");
        break;
      case "/coordinator":
        setPageTitle("Coordinator List");
        break;
      case "/spform":
        setPageTitle("Sponser Register");
        break;
      case "/sponserdetail":
        setPageTitle("sponser List");
        break;
      case "/filter":
        setPageTitle("filter");
        break;
      case "/addpuja":
        setPageTitle("add puja");
        break;
      case "/addcoordinator":
        setPageTitle("add coordinator");
        break;
      case "/orderlist":
        setPageTitle("order List");
        break;

      // Add more cases for other routes as needed
      default:
        setPageTitle("Jkl- Puja Management");
        break;
    }
  };

  useEffect(() => {
    setTitle(); // Set title on component mount
  }, [location.pathname]); // Re-run effect when pathname changes

  const a = useContext(BurgerContext);
  const barClicked = () => {
    a.btnClicked();
  };

  // Logout LOGIC
  function logoutHandler() {
    dispatch(logout());
  }

  return (
    <>
      <div className="v-title sticky-top">
        <div className="pagetite">{pageTitle}</div>

        <div className="d-flex align-items-center justify-content-center">
          <button
            type="button"
            onClick={profileButton}
            className=" py-0 btn-profile"
          >
            <span className="d-flex align-items-center">
              <img
                className="rounded-circle header-profile-user userimg "
                src={userimg}
                alt="Header Avatar"
              />
              <span className="text-start ms-xl-2">
                <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                  {authState.user.name}
                </span>
              </span>
            </span>
          </button>

          <div
            className="float-end justify-content-center align-items-center"
            id="barBtn"
            onClick={barClicked}
          >
            <i className="fa-solid fa-bars p-2"></i>
          </div>
        </div>

        {/* <p>{authState.user?.name}</p>
      <button onClick={logoutHandler}>logout</button>
      <div className="float-end">hello</div>
*/}
      </div>
      <div
        className="profile-menu"
        style={{
          top: toggle ? "50px" : "-105px",
        }}
      >
        <span className="h6 text-capitalize">Welcome Admin !</span>
        <NavLink to="/resetpassword" className="resetpassword d-flex my-2">
          <div>
            <img src={resetIcon} className="resetIcon" />
          </div>
          <div className="text-dark ms-3 text-capitalize">Reset password</div>
        </NavLink>
        <button onClick={logoutHandler} className="d-flex my-2 logoutBtn">
          <div>
            <img src={logoutIcon} className="resetIcon" />
          </div>
          <div className="text-dark ms-3 text-capitalize">Logout</div>
        </button>
      </div>
    </>
  );
}

export default ViewTitle;
