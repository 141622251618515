import baseApi from "./base.api";

const categoryApi = baseApi.injectEndpoints({
    endpoints:(builder)=>({
        GetAllCountry:builder.query({
            query:()=>"/category/country"
        })
    })
})

export const {useGetAllCountryQuery} = categoryApi;