import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const devApi = "http://localhost:8000/api";
const prodApi = "https://jklpul.com/api";

const baseApi = createApi({
  reducerPath: "baseApi",
  tagTypes: ["coordinator", "puja", "puja_selected", "order", "sponsor"],
  baseQuery: fetchBaseQuery({
    baseUrl: prodApi,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        headers.set("authorization", `bearer ${token}`);
      }
    },
  }),
  endpoints: (builder) => ({}),
});

export default baseApi;
