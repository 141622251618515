import baseApi from "./base.api";


const pujaApi = baseApi.injectEndpoints({
    endpoints:(builder)=>({
        AddPuja:builder.mutation({
            query:(payload)=>({
                url:'/puja',
                method:"POST",
                body:payload
            }),
            invalidatesTags:['puja']
        }),

        GetAllPuja:builder.query({
            query:()=>'/puja',
            providesTags:['puja']
        }),
        GetSinglePuja:builder.query({
            query:(id)=>`/puja/${id}`,
            invalidatesTags:['puja']
        }),
        DeletePuja:builder.mutation({
            query:(id)=>({
                url:`/puja/${id}`,
                method:'DELETE',

            }),
           invalidatesTags:['puja']
        }),
        AddSelectedPujaList:builder.mutation({
            query:({id,payload})=>({
                url:`/selected/puja/${id}`,
                method:"POST",
                body:payload
            }),
            invalidatesTags:['puja_selected']
        }),
        GetPujaSelectedList:builder.query({
            query:(id)=>`/selected/puja/${id}`,
            providesTags:['puja_selected']

        })

    })
})

export const {useAddPujaMutation,
    useGetAllPujaQuery,
    useDeletePujaMutation,
    useGetSinglePujaQuery,
    useAddSelectedPujaListMutation,
    useGetPujaSelectedListQuery,
} = pujaApi;