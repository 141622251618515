
import React from 'react'

import '../../css/searchbarcomponent.css'

function SearchBarComponent({setSearch}) {
  return (
    <div className='searchbarcomponent-main-container'>
        <input type="text" placeholder='search name and email' onChange={(e)=>setSearch(e.target.value)} />
        <i className="fa-solid fa-magnifying-glass"></i>
    </div>
  )
}

export default SearchBarComponent