import React, { useContext } from "react";
import "../../css/sidebar.css";
import logo from "../../img/logo192.png";
import { NavLink } from "react-router-dom";
import BurgerContext from "../../context/burgerbutton/BurgerContext";
export default function Sidebar() {
  const { burgerButton, btnClicked } = useContext(BurgerContext);

  const barClicked = () => {
    const mediaWidth = window.matchMedia("(max-width: 940px)");
    if (mediaWidth.matches) {
      btnClicked();
    }
  };
  return (
    <nav className="sb-container navbar navbar-expand-lg d-block p-0">
      <div className=" flex-column align-items-start">
        <div className="d-flex flex-column fs-5 align-items-center  w-100 justify-content-center py-3 bg-body-tertiary">
          <img src={logo} className="img-thumbnail logo-img " alt="logo" />
          <div className="d-block" id="sidebar-none">
            <div className="t-name">Jamgon Kongtrul Labrang</div>
          </div>
        </div>

        <div className="smenu fs-4" id="sidebar-none">
          Menu
        </div>
        <ul className="navbar-nav mb-2 mb-lg-0 flex-column">
          <li className="nav-item">
            <NavLink to="/" className=" menu-li nav-link" onClick={barClicked}>
              <i className="fa-solid menu-icon fa-chart-simple"></i>

              <span id="sidebar-none">dashboard</span>
            </NavLink>
          </li>
          <li className=" nav-item">
            <NavLink
              to="/puja"
              className="menu-li nav-link"
              onClick={barClicked}
            >
              <i className=" menu-icon fa-solid fa-list"></i>

              <span id="sidebar-none">Daily Pujas</span>
            </NavLink>
          </li>
          <li className=" nav-item">
            <NavLink
              to="/orderlist"
              className="menu-li nav-link"
              onClick={barClicked}
            >
              <i className=" menu-icon fa-solid fa-list"></i>

              <span id="sidebar-none">Order List</span>
            </NavLink>
          </li>
          <li className=" nav-item">
            <NavLink
              to="/coordinator"
              className="menu-li nav-link"
              onClick={barClicked}
            >
              <i className=" menu-icon fa-solid fa-list"></i>
              <span id="sidebar-none"> Coordinator list</span>
            </NavLink>
          </li>
          <li className=" nav-item">
            <NavLink
              to="/sponserdetail"
              className="menu-li nav-link"
              onClick={barClicked}
            >
              <i className=" menu-icon fa-solid fa-list"></i>
              <span id="sidebar-none"> Sponsors List </span>
            </NavLink>
          </li>
          <div className="smenu fs-4" id="sidebar-none">
            Management
          </div>
          <li className=" nav-item">
            <NavLink
              to="/spform"
              className="menu-li nav-link"
              onClick={barClicked}
            >
              <i className="fa-solid menu-icon fa-clipboard-list"></i>
              <span id="sidebar-none">Sponsor Register</span>
            </NavLink>
          </li>
          <li className=" nav-item">
            <NavLink
              to="/addcoordinator"
              className="menu-li nav-link"
              onClick={barClicked}
            >
              <i className="fa-solid menu-icon fa-square-plus"></i>
              <span id="sidebar-none">Add Coordinator </span>
            </NavLink>
          </li>
          <li className=" nav-item">
            <NavLink
              to="/addpuja"
              className="menu-li nav-link"
              onClick={barClicked}
            >
              <i className="fa-solid menu-icon fa-square-plus"></i>
              <span id="sidebar-none"> add puja</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
}
