
import Sidebar from "../component/sidebar/Sidebar";
import BurgerContext from "../context/burgerbutton/BurgerContext";
import ViewTitle from "../component/view/ViewTitle";

import {Outlet} from "react-router-dom";
import { useState } from "react";





function RootLayout() {
  const [selectedItems, setSelectedItems] = useState([]);

  const handleRemoveItem = itemId => {
    const updatedItems = selectedItems.filter(item => item.id !== itemId);
    setSelectedItems(updatedItems);
    localStorage.setItem("selectedItems", JSON.stringify(updatedItems));
  };

  const [burgerButton, setBurgerButton] = useState(true);
  const btnClicked = () => {
    setBurgerButton(!burgerButton);
    if (burgerButton == true) {
      document.getElementById("view").style.width = "100%";
      document.getElementById("sidebar").style.top = "0";
      document.getElementById("sidebar").style.left = "0";
      document.getElementById("sidebar").style.width = "40%";
      document.getElementById("sidebar").style.zIndex = "9999";
    } else {
      document.getElementById("sidebar").style.left = "-600px";
      document.getElementById("view").style.width = "100%";
    }
  };
  return (
    <div className="App">    
        <BurgerContext.Provider value={{ burgerButton, btnClicked }}>
              <div className="container-fluid" id="containerFluid">
                <div className="row main-row">
                  <div className="main-sidebar p-0" id="sidebar">
                    <Sidebar></Sidebar>
                  </div>
                  <div className="main-view p-0" id="view">
                    <ViewTitle />
                    {/* route section */}
                    <Outlet/>
                  </div>
                </div>
              </div>
            </BurgerContext.Provider>
    </div>
  );
}

export default RootLayout;
