import React, { useState,useEffect } from "react";

import logo from "../../img/logo192.png";
import { useForgetPasswordMutation } from "../../handler/auth.api";

//css
import "../../css/spforma.css";
import "../../css/signin.css";
import { ToastError, SuccessToast } from "../Toast/Notification";
import { ToastContainer } from "react-toastify";

export default function ResetPassword() {
  const [email, setEmail] = useState("");
  // api handler
  const [forgetPasswordApi, { isLoading, data, isError, isSuccess,error}] =
    useForgetPasswordMutation();
  async function formHandler(e) {
    e.preventDefault();
    try {
      const response = await forgetPasswordApi({ email });
    } catch (err) {
      ToastError(err.message);
    }
  }
  useEffect(()=>{
    if(isSuccess){
      SuccessToast('check your email,password reset is send');
    }else if(isError){
      ToastError(error.data.message);
    }
  },[isSuccess,isError])

  return (
    <>
      <div className="d-flex align-items-center justify-content-center sign-main-container">
        <div className="signin-container">
          <div className="bg-danger d-flex  align-items-center justify-content-center p-2">
            <div className="signin-img">
              <img src={logo} alt="jklabrang" className="signlogo" />
            </div>
            <div className="sigin-title h4 mb-0 text-capitalize text-white">
              Jamyang knogturlku labrang
            </div>
          </div>

          <div className="signsection ">
            <div className="h5 text-center text-capitalize text-danger">
              Reset Password
            </div>
            <div className="mb-4 text-center text-danger-emphasis">
              Enter your email to reset your password
            </div>
            <form onSubmit={formHandler}>
              <div className="mb-3">
                <label htmlFor="signEmail" className="form-label">
                  Email address
                </label>
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  className="fi-input"
                  id="signEmail"
                  name="email"
                />
              </div>

              <button type="submit" className="btn btn-primary">
                {isLoading ? <div className="spinnerloader "></div> : "submit"}
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
