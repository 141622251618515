import baseApi from "./base.api";

const sponsorApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    AddSponsor: builder.mutation({
      query: (payload) => ({
        url: "/sponsor",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["sponsor"],
    }),
    DeleteSponsor: builder.mutation({
      query: (id) => ({
        url: `/sponsor/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["sponsor"],
    }),
    GetAllSponsor: builder.query({
      query: (query) => `/sponsor/?${query}`,
      providesTags: ["sponsor"],
    }),
  }),
});

export const {
  useAddSponsorMutation,
  useGetAllSponsorQuery,
  useDeleteSponsorMutation,
} = sponsorApi;
