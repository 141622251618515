import React, { useEffect, useState } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import "../../../css/selectpuja.css";
import { motion } from "framer-motion";
// TOAST LIBARARY
import { SuccessToast, ToastError } from "../../Toast/Notification";
import { ToastContainer } from "react-toastify";

import {
  useGetAllPujaQuery,
  useAddSelectedPujaListMutation,
} from "../../../handler/puja.api";

export default function SelectPuja() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [pujaSelectedList, setPujaSelectedList] = useState([]);

  // API CALLING
  const getAllPujaApi = useGetAllPujaQuery();
  const [addSelectedPujaListApi] = useAddSelectedPujaListMutation();

  function CheckPujaSelected(id) {
    if (pujaSelectedList.length < 1) {
      return false;
    } else {
      return pujaSelectedList.filter((item) => {
        if (item.id == id) {
          return true;
        } else {
          return false;
        }
      });
    }
  }

  function PujaSelectedHandler(title, id) {
    if (CheckPujaSelected(id) == false) {
      setPujaSelectedList([...pujaSelectedList, { title, id }]);
    } else {
      setPujaSelectedList(pujaSelectedList.filter((item) => item.id !== id));
    }
  }

  function SelectAllPujaHandler() {
    const allPujalist = [];
    getAllPujaApi.data?.data.map((item) => {
      allPujalist.push({ title: item.title, id: item._id });
    });
    setPujaSelectedList(allPujalist);
  }

  async function pujaSelectHandler() {
    if (pujaSelectedList.length < 1) {
      ToastError("Please Select Puja");
    } else {
      try {
        const pujalist_id = [];
        pujaSelectedList.map((item) => {
          pujalist_id.push({ selectedPuja: item.id });
        });
        const response = await addSelectedPujaListApi({
          id,
          payload: pujalist_id,
        });
        if (response.error) {
        } else {
          navigate(`/spform/selectpuja/confirmpuja/${id}`);
        }
      } catch (err) {}
    }
  }

  // console.log(getAllPujaApi.data?.data);
  return (
    <section className="pujaselect-page-main-container">
      <ToastContainer />
      <section className="select-all-puja-and-puja-selected-list-container">
        <div>
          <h1>TOTAL DAILY PUJAs {getAllPujaApi.data?.data.length} / </h1>
          {pujaSelectedList.length >= 1 ? (
            <h1>{`Selected ${pujaSelectedList.length} Puja `}</h1>
          ) : (
            <h1>Daily Pujas Selected</h1>
          )}
        </div>
        <div>
          {pujaSelectedList.length === getAllPujaApi.data?.data.length ? (
            <button
              style={{
                backgroundColor: "tomato",
                color: "white",
                border: "none",
              }}
              onClick={() => setPujaSelectedList([])}
            >
              Deselect All
            </button>
          ) : (
            <button onClick={SelectAllPujaHandler}>Select All</button>
          )}
        </div>
      </section>
      <section className="puja-list-section">
        {getAllPujaApi.isLoading && <div className="bigSpinner"></div>}

        {getAllPujaApi.data?.data.map((puja, index) => {
          return (
            <motion.div
              className="puja-card-container"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                type: "spring",
                stiffness: 100,
                ease: "linear",
                duration: 0.2,
                delay: index / 15,
              }}
              key={puja._id}
              onClick={() => PujaSelectedHandler(puja.title, puja._id)}
            >
              <img src={puja.image} alt={puja.title} />
              <h2>{puja.title}</h2>
              {CheckPujaSelected(puja._id).length > 0 && (
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  className="puja-select-icon-container"
                >
                  <motion.i
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{
                      type: "spring",
                      stiffness: 200,
                      ease: "linear",
                      duration: 0.2,
                    }}
                    className="fa-solid fa-check"
                  ></motion.i>
                </motion.div>
              )}
            </motion.div>
          );
        })}
      </section>
      <section className="select-puja-next-and-back-button-container d-flex justify-content-end">
        <button onClick={pujaSelectHandler}>Next</button>
      </section>
    </section>
  );
}
