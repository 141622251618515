import React, { useEffect, useState } from "react";
import "../../../css/confirmpuja.css";
import { useLocation } from "react-router-dom";
import { ToastContainer} from "react-toastify";
import photoUrl from "../../../img/mb.jpeg";

// toast



import { NavLink,useParams,useNavigate} from "react-router-dom";


import {useGetPujaSelectedListQuery} from "../../../handler/puja.api"
import AddDedicatedComponent from "../../AddDedicatedComponent";
import PujaConfirmBox from "./PujaConfirmBox";



export default function ConfirmPuja() {

  const[cost,setCost] =useState(0);

  const[dedicatorList,setDedicatorList] = useState([]);
  const[openDedicatedForm,setOpenDedicatedForm] = useState(false);
  const {id} = useParams();
  const[_id,setId] = useState();




  // API CALLING
  const getSelectedPujaListApi = useGetPujaSelectedListQuery(id);


  return (
    <div>
      {/* Toast Section-------------- */}
        <ToastContainer/>
      {/* ------------------------------- */}
      <div className="puja-confirm-parent-wrapper">
        {/* DEDICATED FORM SECTON_------------------ */}
        {
          openDedicatedForm&&
          <AddDedicatedComponent 
             setOpenDedicatedForm={setOpenDedicatedForm}
             dedicatorList={dedicatorList}
             parent_id={_id} 
             setId={setId}
             setDedicatorList={setDedicatorList}/>

        }
        {/* ------------------------------------------- */}
      <div className="puja-confirm-addmore-and-add_dedicate-container">
        <button>Add More</button>
        <button onClick={()=>setOpenDedicatedForm(true)}>Add Dedicated</button>
      </div>


        <div className="puja-confirm-puja-list-container">
          {
            getSelectedPujaListApi.data?.data.map((item)=>{
              return(
                <PujaConfirmBox 
                  key ={item._id}
                  item={item} 
                  setOpenDedicatedForm={setOpenDedicatedForm} 
                  idParams ={id} 
                  setId={setId}
                  dedicatorList={dedicatorList}
                  setDedicatorList={setDedicatorList}/>
              )
            })
          }
        </div>
      </div>
    </div>
  );
}
