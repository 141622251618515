
import {Outlet,Navigate,useLocation} from "react-router-dom"
import { useSelector } from "react-redux"


function PreventSigninRoute() {
    const location = useLocation();
    const authState = useSelector((state)=>state.auth);
  return (
        authState.user?<Navigate to="/"state={{from:location}} replace/>:<Outlet/>
  )
}

export default PreventSigninRoute