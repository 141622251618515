import { SuccessToast, ToastError } from "../component/Toast/Notification";
import { useDeleteOrderMutation } from "../handler/order.api";

export default function OrderDeleteComponent({ id }) {
  const [deleteOrderApi, { isLoading }] = useDeleteOrderMutation();

  async function DeleteOrderHandler(id) {
    try {
      const response = await deleteOrderApi(id);
      console.log(response);
      if (response.error) {
        console.log("Error: ", response.error?.data.message);

        ToastError(response.error?.data.message);
      } else {
        console.log("Order deleted successfully");
        SuccessToast("Successfully Delete Order");
      }
    } catch (err) {
      console.log(err);
      ToastError("SERVER ERROR! TRY AGAIN");
    }
  }
  return (
    <div className="pi-delete" onClick={() => DeleteOrderHandler(id)}>
      {isLoading ? (
        <span className="spinnerloader"></span>
      ) : (
        <i className="fa-solid fa-trash"></i>
      )}
    </div>
  );
}
