import React, { useState } from "react";
import "../../css/singlesponsororder.css";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { useGetSponsorOrderListQuery } from "../../handler/order.api";

import PaginateComponent from "../filter/PaginateComponent";
import MoreDetail from "./ordermore/MoreDetail";

function SingleSponsorOrder() {
  const [page, setPage] = useState(1);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [selectedPaidStatus, setSelectedPaidStatus] = useState("unpaid");

  const params = useParams();
  // API
  const orderListApi = useGetSponsorOrderListQuery(params.id);
  const totalPages = orderListApi.data?.numberOffPages;
  console.log(orderListApi.data);
  const handleSelectOrder = (item) => {
    setSelectedOrder(item);
    setIsDetailVisible(true);
    setSelectedPaidStatus(item.paid ? "paid" : "unpaid");
  };

  const handleClose = () => {
    setSelectedOrder(null);
    setIsDetailVisible(false);
  };
  const handleSelectChange = (event) => {
    setSelectedPaidStatus(event.target.value);
  };
  // Assuming the data array has at least one item
  const firstItem = orderListApi.data?.data[0];
  console.log(firstItem);
  const location = useLocation();
  const pathSegments = location.pathname.split("/"); // Split the pathname by '/'
  const paramId = pathSegments[pathSegments.length - 1]; // Get the last segment

  return (
    <div>
      {/* Order list */}
      {/* heading */}
      {orderListApi.data?.data.length === 0 ? (
        <div className="sp-indi d-flex align-items-center justify-content-between">
          <div className="sp-name">{firstItem?.sponsor?.name}</div>
          <div className="sp-country">{firstItem?.sponsor?.country}</div>
        </div>
      ) : (
        <div className="sp-indi d-flex align-items-center justify-content-between">
          <div className="sp-name">{firstItem?.sponsor?.name}</div>
          <div className="sp-country">{firstItem?.sponsor?.country}</div>
        </div>
      )}
      <div className="ol-container">
        <div className="ol-head text-capitalize color">
          <div>id</div>
          <div>Puja</div>
          <div className="ol-sm">Sponsor</div>
          <div className="ol-sm">Duration </div>
          <div className="ol-sm">Country</div>
          <div>End Date</div>
          <div>Co.Code</div>
          <div>Transaction</div>
        </div>
        {orderListApi.data?.data.length === 0 ? (
          <div className="text-center my-4">
            Sponsor added with no order. Click here to place an order.
            <NavLink
              to={`/spform/selectpuja/${paramId}`}
              className="singleOrder-action-box"
            >
              <i className="fa-solid fa-cart-plus"></i>
            </NavLink>
          </div>
        ) : (
          <div className="ol-list-con">
            {orderListApi.isLoading && <div className="bigSpinner"></div>}
            {orderListApi.data?.data.map((item) => {
              return (
                <div
                  key={item?.id}
                  className="ol-list ol-more"
                  style={{
                    color: `${item.paid ? "green" : "#655959"}`,
                  }}
                  onClick={() => handleSelectOrder(item)}
                >
                  <div>{item?.id}</div>
                  <div>{item?.pujaName}</div>
                  <div>{item?.sponsor?.name}</div>
                  <div className="ol-sm">{item.duration}</div>
                  <div>{item?.sponsor?.country}</div>
                  <div className="ol-sm">{item.endDate.slice(0, 10)}</div>
                  <div className="ol-sm">
                    {item?.sponsor?.coordinator?.code
                      ? item.sponsor?.coordinator?.code
                      : `No Coord`}
                  </div>
                  <div className="d-flex align-items-center justify-content-center">
                    <span
                      className="ol-payment"
                      style={{
                        backgroundColor: `${item.paid ? "green" : "tomato"}`,
                      }}
                    >
                      {item.paid ? "paid" : "unpaid"}
                      {/* {selectedPaidStatus} */}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {/* More details modal */}
        {isDetailVisible && selectedOrder && (
          <MoreDetail
            item={selectedOrder}
            isVisible={isDetailVisible}
            handleClose={handleClose}
            selectedPaidStatus={selectedPaidStatus}
            handleSelectChange={handleSelectChange}
            setSelectedPaidStatus={setSelectedPaidStatus}
          />
        )}
      </div>
    </div>
  );
}

export default SingleSponsorOrder;
