import React from "react";

import { useGetAllCoordinatorQuery } from "../../handler/coordinator.api";
import "../../css/filter.css";

function FilterCoordinatorComponent({ setCoordinatorCode }) {
  const coordinatorApi = useGetAllCoordinatorQuery();

  return (
    <div className="filter-drop-down-general-design">
      <select
        onChange={(e) => setCoordinatorCode(e.target.value)}
        defaultValue={""}
      >
        <option value="">All Coordinator</option>
        {coordinatorApi.data?.data.map((item, id) => {
          return (
            <option value={item._id} key={id}>
              {item.code}
            </option>
          );
        })}
      </select>
    </div>
  );
}

export default FilterCoordinatorComponent;
